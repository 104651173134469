<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <b-card>
              <b-card-body class="p-0">
                <b-card-title>
                  Manage which endorsements appear on your website.
                </b-card-title>
                <div class="guide-tip">
                  <b-row align-v="center">
                    <b-col cols="3" lg="1" class="icon">
                      <font-awesome-icon :icon="['fas', 'users']" />
                    </b-col>
                    <b-col cols="12" lg="8" order="3" order-lg="2" class="text">
                      <div v-if="hasSubmittedItems">
                        <div>
                          Now that you have endorsements you can choose to show
                          them on your site. You can click
                          <span class="font-weight-bold">Show All</span> to
                          allow all endorsements to show on your website or you
                          can use the action bar below to update the visibility
                          of each endorsement individually.
                        </div>
                        <div class="mt-2" v-if="isPetition">
                          As a petition candidate, you can track the number of
                          signatures you received on the next step in this
                          wizard.
                        </div>
                      </div>
                      <div v-else>
                        Once you have submitted an endorsement to the WGAW for
                        eligibility review, the endorsement will show on this
                        page. From here, you will be able to choose which member
                        endorsements to post on your website.
                      </div>
                    </b-col>
                    <b-col
                      cols="9"
                      lg="3"
                      order="2"
                      order-lg="3"
                      class="button"
                    >
                      <b-button
                        variant="outline-secondary"
                        size="sm"
                        v-b-modal.modal-theme-guide
                      >
                        View Example
                        <font-awesome-icon :icon="['far', 'window-maximize']" />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="hasSubmittedItems">
                  <b-button
                    variant="outline-secondary"
                    class="mt-3 mb-4"
                    @click="exportAll"
                    :disabled="exporting"
                    ><b-spinner
                      small
                      style="position: relative; top: -3px"
                      v-if="exporting"
                    ></b-spinner>
                    Export All
                    <font-awesome-icon :icon="['fas', 'file-excel']" />
                  </b-button>
                  <b-button
                    variant="primary"
                    class="mt-3 mb-4 float-right"
                    @click="showAll"
                  >
                    Show All
                    <font-awesome-icon :icon="['fas', 'check-square']" />
                  </b-button>
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>

                  <div
                    v-for="(statement, index) in submittedEndorsements"
                    :key="index"
                    class="mt-2"
                  >
                    <h5>Statement #{{ statement.number }}</h5>
                    <b-table
                      striped
                      responsive
                      ref="table"
                      :fields="fields"
                      :items="statement.tableItems"
                      :sort-by="sortBy"
                      :sort-desc="sortDesc"
                      class="reorderable mb-4"
                      v-sortable="sortableOptions"
                    >
                      <template #cell(id)="data">
                        <b-icon
                          icon="arrows-move"
                          aria-hidden="true"
                          :data-item-id="data.item.id"
                        ></b-icon>
                      </template>
                      <template #cell(statementDetail)="data">
                        <div class="statement-detail">
                          <ul>
                            <li>
                              <label class="font-weight-bold">Name: </label>
                              <span>
                                {{ data.item.firstName }}
                                {{ data.item.lastName }}
                              </span>
                            </li>
                            <li>
                              <label class="font-weight-bold">Email: </label>
                              <span>
                                {{ data.item.email }}
                              </span>
                            </li>
                            <li>
                              <label class="font-weight-bold">Position:</label>
                              <div>
                                <span v-html="data.item.positionText"></span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </template>
                      <template #cell(positions)="data">
                        <span v-html="data.item.positionText"></span>
                      </template>
                      <template #cell(action)="data">
                        <b-form-select
                          v-model="data.item.action"
                          @change="onOptionChange($event, data.item)"
                          :options="actionOptions"
                        ></b-form-select>
                      </template>
                    </b-table>
                  </div>
                </div>
              </b-card-body>
            </b-card>
            <b-alert class="mt-4" variant="primary" :show="hasSubmittedItems">
              <span class="font-weight-bold">Note: </span>When saving this page
              your changes will be displayed on your website immediately.
            </b-alert>
            <AdminFooterButton
              @on-save="onSave"
              @on-back="onBack"
              @on-preview="onPreview"
              @on-skip="onSkip"
              :hide-skip-button="!isPetition"
              :save-text-button="saveText"
              :saving="saving"
              :invalid="false"
              :hide-save-button="!hasSubmittedItems"
              :hide-dashboard-button="!showDashboardButton"
            />
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="endorsementList"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import { Sortable } from "sortablejs";
import CandidateMixin from "@/mixins/candidate.mixin";
import { setEndorsementPosition } from "@/services/helpers";
import Repository from "@/repositories/RepositoryFactory";

const ExportRepository = Repository.get("ExportRepository");

const createSortable = (el, options, vnode) => {
  return Sortable.create(el, {
    ...options
  });
};
export default {
  name: "ManageApprovedEndorsements",
  mixins: [CandidateMixin],
  directives: {
    sortable: {
      name: "sortable",
      bind(el, binding, vnode) {
        const table = el;
        table._sortable = createSortable(
          table.querySelector("tbody"),
          binding.value,
          vnode
        );
      }
    }
  },
  data() {
    return {
      loading: false,
      exporting: false,
      errorMessage: null,
      saveText: "Save & Continue",
      hideSkip: false,
      endorsementsTable: [],
      sortBy: "tableOrder",
      sortDesc: false,
      fields: [
        {
          label: "",
          key: "id"
        },
        {
          label: "#",
          key: "tableOrder"
        },
        {
          label: "Detail",
          key: "statementDetail"
        },
        // {
        //   label: "First Name",
        //   key: "firstName",
        // },
        // {
        //   label: "Last Name",
        //   key: "lastName",
        // },
        // {
        //   label: "Position",
        //   key: "positions",
        // },
        // {
        //   label: "Email",
        //   key: "email",
        // },
        {
          label: "Action",
          key: "action",
          class: "col-action"
        }
      ],
      actionOptions: [
        {
          text: "Show",
          value: 1
        },
        {
          text: "Hide",
          value: 2
        },
        {
          text: "Remove",
          value: 3
        }
      ],
      sortableOptions: {
        chosenClass: "is-selected",
        onSort: function(/**Event*/ evt) {
          //   //Dragged item new position
          //   console.log(`New Position Index: ${evt.newIndex}`);
          //   console.log(`Old Position Index: ${evt.oldIndex}`);
          //   var itemEl = evt.item; // dragged HTMLElement
          //   //   console.log(itemEl, "onSort");
          //   console.log(evt);
        }
      },
      items: [],
      tableItems: []
    };
  },
  created() {},
  async mounted() {
    if (!this.isPetition) this.saveText = "Save";
  },
  computed: {
    showDashboardButton() {
      return !this.candidateModel.isPetition;
    },
    isPetition() {
      return this.candidateModel.isPetition;
    },
    hasSubmittedItems() {
      let result = false;
      for (let element of this.submittedEndorsements) {
        result = element.tableItems.length > 0;
        if (result) break;
      }
      return result;
    },
    submittedEndorsements() {
      return this.candidateModel.endorsementStatementsTableItems.map(function(
        statement
      ) {
        return {
          ...statement,
          tableItems: statement.items
            .filter(item => item.endorsementStatus === "Submitted")
            .map(function(item2, index) {
              return {
                ...item2,
                tableOrder: index + 1,
                positionText:
                  item2.positions === ""
                    ? ""
                    : item2.positions
                        .split(",")
                        .map(id => setEndorsementPosition(id))
                        .join("<br>")
              };
            })
        };
      });
    }
  },
  methods: {
    onOptionChange(e, args) {},
    showAll() {
      let table = document.querySelectorAll(".reorderable tbody tr");
      for (let i = 0; i < table.length; i++) {
        let td = table[i].querySelectorAll("td")[3];
        let select = td.firstChild;
        select.value = 1;
        //dispatch change event to update the bind item action value
        let changeEvent = new Event("change");
        select.dispatchEvent(changeEvent);
      }
    },
    exportAll() {
      try {
        this.exporting = true;
        let isAdmin = this.$store.state.auth.user.roles.includes("Admin");
        setTimeout(async () => {
          await ExportRepository.ExportSubmittedEndorsements(
            this.$route.params.id,
            isAdmin
          );
          this.exporting = false;
        }, 500);
      } catch (e) {
        console.log(e);
      }
    },
    onPreview() {},
    onBack() {
      if (this.hasUnsubmittedItems)
        this.$router.push("unapproved-endorsements");
      else this.$router.push("add-endorsement");
    },
    onSkip() {
      this.$router.push("manage-petition");
    },
    async onSave() {
      this.loading = true;
      this.saving = true;
      this.checkEndorsementTable();

      let data = {
        userId: this.$route.params.id,
        items: this.tableItems
      };

      this.candidateService.SaveEndorsementTableAsync(data).then(res => {
        setTimeout(() => {
          this.$store.commit("candidates/SET_CANDIDATE", res.data);
          this.saving = false;
          if (this.candidateModel.isPetition) {
            this.$router.push("manage-petition");
          } else {
            this.$router.push(`dashboard`);
          }
        }, 500);
      });
    },
    checkEndorsementTable() {
      let vm = this;
      let endorsements = this.submittedEndorsements;
      let tables = document.querySelectorAll(".reorderable");

      for (let t = 0; t < tables.length; t++) {
        let tr = tables[t].querySelectorAll("tbody tr");
        let order = 1;
        for (let i = 0; i < tr.length; i++) {
          let td = tr[i].querySelectorAll("td")[1].innerHTML;
          let selectedAction = tr[i]
            .querySelectorAll("td")[3]
            .querySelector(".custom-select").value;
          if (endorsements[t].items[parseInt(td - 1)]) {
            endorsements[t].items[parseInt(td - 1)].order = order;
            endorsements[t].items[parseInt(td - 1)].action = selectedAction;
            order++;
          }
        }
      }

      endorsements.map(function(item) {
        return item.items.map(x => {
          vm.tableItems.push({
            publicEndorsementId: x.publicEndorsementId,
            action: parseInt(x.action),
            order: parseInt(x.order),
            endorsementStatementId: x.endorsementStatementId
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-wizard {
  .table {
    font-size: 14px;

    .col-action {
      .custom-select {
        font-size: 14px;
        width: 95px;
      }
    }

    .statement-detail {
      font-size: 14px;
      line-height: 20px;

      label {
        margin-bottom: 0;
      }

      li {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
